import logo from './logo.png';
import { Route, Routes } from "react-router-dom";
import './App.css';

function Main() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h5>
           We'll be back to finish our site after we complete development on our 90's inspired racing game:
        </h5>
        <h2>
           <div className="MainTitle">Element Racers:</div>
           <div className="SubTitle">Veggie Vendetta</div>
        </h2>
      </header>
    </div>
   )
}

function App() {
  return (
    <Routes>
      <Route path='/' element={Main()} />
      <Route path='/live' Component={() => {
        window.location.replace('https://twitch.tv/tatertechstudios');
      }}/>
     </Routes>
  );
}

export default App;
